.midContac {
  position: relative;
  opacity: 0.99;
}

.midContac:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-size: cover;
  opacity: 0.86;
}

#rC {
  padding: 50px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  /* Adjust for larger screens */
  margin: 0 auto;
  /* Center the form horizontally */
  padding: 20px;
  border-radius: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.intr-image {
  width: 767px;
  height: 703px;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

textarea {
  width: 100%;
  height: 75px;
  /* Adjust as needed */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  resize: vertical;
  /* Allow vertical resizing */
}

button[type="submit"] {
  background-color: #4CAF50;
  /* Green color */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive Adjustments (Media Queries): */

/* For screens smaller than 768px */
@media (max-width: 768px) {
  .form {
    max-width: 90%;
    text-align: start;
    /* Adjust for smaller screens */
  }

  .form-group label {
    /* Make labels stack vertically on small screens */
    display: inline-block;
    width: 120px;
    /* Adjust width if needed */
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    /* Adjust width for smaller screens */
    width: 415px;
    /* Subtract label width */
  }

  .midContac {
    display: none;
  }

  #makeBlock {
    display: block;
  }

}

#startCaro {
  text-align: start;
}

/* For even smaller screens (very small screens) */
@media (max-width: 480px) {
  #contactImg{
    width: 400px;
    height: 400px;
  }
  .form-group label {
    /* Reduce label width for very small screens */
    width: 80px;
    /* Adjust as needed */
  }

  .form {
    max-width: 90%;
    text-align: start;
    /* Adjust for smaller screens */
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    /* Adjust width for very small screens */
    width: 150px;
    /* Subtract adjusted label width */
  }

  /* Increase font size for better readability */
  .form-group label,
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea,
  button[type="submit"] {
    font-size: 14px;
    /* Adjust as needed */
  }

  #startCaro {
    text-align: center;
  }
}

.paraText {
  text-align: left;
  font-size: 20px;
  color: whitesmoke;
  font-weight: 500;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.1);
}


#carousel {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-blend-mode: overlay;
  padding-bottom: 50px;
  z-index: 4;
}

#carousel::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-image: url('../images/bg.jpg'); */
  /* Background image */
  background-size: cover;
  background-position: center;
  filter: blur(2.5px);
  /* Apply blur effect to the background image */
  z-index: 1;
  opacity: 0.8;
  /* Adjust opacity to your preference */
  background-blend-mode: overlay;
}

#carousel::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  --tw-bg-opacity: 1;
  background-color: #F9F8F6;

  z-index: 1;
  opacity: 0.70;
  /* Adjust opacity to your preference */
}

#carousel .container,
#carousel .row,
#carousel .form {
  position: relative;
  /* Ensures content is above the overlay */
  z-index: 2;
  /* Places content above the background and overlays */
}

/* Ensure the button text remains visible and centered */
#btnSubmit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}


.nav-item a.nav-link {
  transition: color 0.3s ease-in-out;
}

.nav-item a.nav-link:hover {
  color: #196C68 !important;
  cursor: pointer;
}

#btnSubmit {
  /* Default button styling */
  padding: 12px 29px;
  background-color: #10886F;
  /* Initial background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* Initial box-shadow */
  border: none;
  /* No border */
  border-radius: 5px;
  /* Rounded corners */
  color: white;
  /* Initial text color */
  cursor: pointer;
  /* Pointer cursor on hover */
  position: relative;
  /* Positioning context for pseudo-elements */
  overflow: hidden;
  /* Hide overflow of pseudo-elements */
  font-size: 15px;
  /* Font size */
  font-weight: 500;
  /* Font weight */
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  /* Smooth transition */
}

#btnSubmit::before,
#btnSubmit::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  transition: transform 0.3s ease;
  z-index: 0;
  /* Ensure pseudo-elements are behind text */
}

#btnSubmit::before {
  left: 0;
  background-color: #3477c0;
  /* Background color for sliding effect */
  transform: translateX(-100%);
}

#btnSubmit::after {
  right: 0;
  background-color: #3477c0;
  /* Background color for sliding effect */
  transform: translateX(100%);
}

#btnSubmit:hover::before,
#btnSubmit:hover::after {
  transform: translateX(0);
  /* Slide effect on hover */
}

#btnSubmit:hover {
  background-color: #196C68;
  /* Change background color on hover */
  box-shadow: 0 7px 14px rgba(0, 0, 0, 0.25), 0 5px 5px rgba(0, 0, 0, 0.22);
  /* Hover effect for box-shadow */
  color: white;
}

#btnSubmit span {
  position: relative;
  /* Relative position for stacking context */
  z-index: 1;
  /* Ensure text stays above pseudo-elements */
  transition: color 0.3s ease;
  /* Smooth transition for text color */
}

/* Ensure the button text remains visible and centered */
#btnSubmit {
  display: flex;
  align-items: center;
  justify-content: center;
}

img.elementor-9 {
  width: 112px;
  height: 112px;
  position: absolute;
  top: -50px;
  left: 15px;
}

img.about5ims {
  z-index: 1;
  position: relative;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

span.consultingspan {
  border-radius: 2px;
  padding: 10px;
  background: rgba(0, 125, 231, 0.10);
  color: #015c91;
  font-weight: 500;
}

.abou-5textarea5 {
  padding: 0px 0px 0px 50px;
  text-align: start;
  color: #015c91;
}

.about5-authorarea {
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.08);
  padding: 24px 20px;
}

.about5-authorarea:hover {
  background: #425b76;
  transform: translateY(-10px);
  color: white;
  transition: all .4s ease-in-out;
}

.margin-t32 {
  margin-top: 20px;
}

.author0mages {
  position: absolute;
  border-radius: 64px;
  background: #007DE7;
  display: inline-block;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 16px;
  position: relative;
  z-index: 1;
  transition: all .4s ease-in-out;
  margin-top: 10px;
}


.author0mages::after {
  position: absolute;
  content: "";
  top: -10px;
  background: #007DE7;
  left: -10px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.3;
  transition: all .4s ease-in-out;
}

.about5-authortext {
  margin-left: 50px;
  margin-top: -20px;
}

#missionCss {
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
}



span.aboutspan {
  border-radius: 2px;
  background: rgb(16 136 111 / 8%);
  padding: 8px 12px;
  color: #F1BE14;
  margin-bottom: 24px;
  font-weight: 500;
  line-height: 16px;
}

.header10-textarea {
  text-align: start;
  padding: 25px;
}


.opacity2 {
  opacity: 0.9;
}


img.homewelbg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.elips-icon {
  border-radius: 118.421px;
  background: #196C68;
  display: inline-block;
  height: 120px;
  width: 120px;
  text-align: center;
  line-height: 120px;
  position: absolute;
  top: 40%;
  left: 39%;
}

.ellipsarea1 {
  position: absolute;
  top: -50px;
  left: 207px;
}

.exportellips {
  background: #fff;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: 8px;
  left: 9px;
}

.ellipsarea2 {
  position: absolute;
  top: 215px;
  left: -50px;
}

.ellipsarea3 {
  position: absolute;
  bottom: -45px;
  left: 215px;
}

.ellipsarea4 {
  position: absolute;
  top: 41%;
  right: -45px;
}

.keyframe3 {
  position: relative;
  animation-name: animation-3;
  animation-duration: 150s;
  animation-iteration-count: alternate;
  animation-direction: infinite;
}

@keyframes animation-3 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1000deg);
  }
}



@media (max-width:1400px) {
  .intr-image {
    width: 567px;
    height: 503px;
  }
}

@media (max-width:1050px) {
  .intr-image {
    width: 467px;
    height: 403px;
  }

  #resposniveCaro {
    display: block;
  }

  #rowReverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .about5ims {
    margin-top: 50px;
  }

  #centerData {
    text-align: center;
  }

  .consultingspan {
    margin-bottom: 0px;
  }
}

@media (max-width:990px) {
  .welcome-bgarea {
    margin-top: 75px;
  }
}

@media (max-width:900px) {
  .intr-image {
    width: 400px;
    height: 350px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .ellipsarea1 {
    left: 300px;
  }

  .ellipsarea2 {
    left: -37px;
    top: 250px;
  }

  .ellipsarea3 {
    left: 310px;
  }

  .ellipsarea4 {
    right: -36px;
    top: 36%;
  }
}

@media screen and (max-width: 424px) {
  .ellipsarea4 {
    right: -12px;
    top: 35%;
  }

  .ellipsarea3 {
    left: 133px;
  }

  .ellipsarea2 {
    left: -12px;
    top: 125px;
  }

  .ellipsarea1 {
    left: 125px;
  }
}


@media (max-width: 767px) {
  .intr-image {
    width: 367px;
    height: 303px;
  }

  .elips-icon {
    left: 33%;
    top: 33%;
  }
}

@media (max-width:400px) {
  #contactImg{
    width: 350px;
    height: 350px;
  }
}