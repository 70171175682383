.service {
    background-color: #daf1e9;
    border-radius: 0rem;
    padding: 30px 15px;
    margin: 10px 0px 10px 0px;
    height: 425px;
}

.service-title {
    margin-top: 15px;
}

.service-title a{
    color: black;
    text-decoration: none;
}

.service-text {
    list-style-type: none;
    padding-left: 0;
}

.service-text li {
    margin-bottom: 10px;
    padding-left: 20px;
    position: relative;
    font-size: 0.95rem;
    color: #555;
    line-height: 1.5;
}

.service-text li::before {
    content: '✔';
    color: #10886F;
    font-weight: bold;
    position: absolute;
    left: 0;
    top: 0;
}

@media (min-width:576px) and (max-width:770px) {
    .service {
        height: 510px;
    }
}