
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
  background: linear-gradient(145deg, rgba(16, 136, 111, 0.8), #f1be14, #c9a514, rgba(193, 150, 20, 1));
  overflow: hidden;
}

.loading-logo {
  width: 100px; /* Default size for small screens */
  animation: popInOut 1.7s ease-in-out infinite;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.1)); /* Adds a subtle shadow */
}

@media (min-width: 480px) {
  .loading-logo {
    width: 150px;
  }
}

@media (min-width: 768px) {
  .loading-logo {
    width: 200px;
  }
}

@media (min-width: 1024px) {
  .loading-logo {
    width: 250px;
  }
}

@media (min-width: 1440px) {
  .loading-logo {
    width: 300px;
  }
}

/* Animation for popping in and popping out */
@keyframes popInOut {
  0%, 100% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.1);
    opacity: 1;
  }
  60% {
    transform: scale(1);
    opacity: 1;
  }
}
